<template>
    <div class="license-requests-content">
        <DxDataGrid 
            @context-menu-preparing="addMenuItems"
            :style="{ flexBasis: flexBasis }"
            ref="licenseRequestsDataGrid"
            :selection="{ mode: 'single' }"
            :data-source="requestsStore" 
            :allow-column-reordering="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :show-borders="true"
            @selection-changed="onSelectionChanged"
            @row-prepared="onRowPrepared"
            @toolbar-preparing="onToolbarPreparing($event)"
        >
            <DxColumn data-field="id" data-type="number" caption="ID" :visible="false" />
            <DxColumn data-field="firmName" data-type="string" caption="Firm Name" />
            <DxColumn data-field="clientIp" data-type="string" caption="Client IP Address" />
            <DxColumn data-field="clientId" data-type="string" caption="Client Identity" :visible="false" />
            <DxColumn data-field="serverName" data-type="string" caption="Server Name" />
            <DxColumn data-field="databaseName" data-type="string" caption="Database Name" />
            <DxColumn data-field="createAt" data-type="datetime" caption="Create At" />
            <DxColumn data-field="lastModifyDate" data-type="datetime" caption="Last ModifyDate" />
            <DxColumn 
                data-field="requestDataChanged" 
                data-type="bool" 
                caption="Request Data Changed" 
                :visible="false"
                :show-in-column-chooser="false" />
            <DxFilterRow :visible="true" />
            <DxSearchPanel :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxLoadPanel :enabled="true"/>
            <DxColumnChooser :enabled="true"/>
            <DxPaging 
                :enabled="true"
                :page-size="page.size"
                :page-index="page.number"
            />
            <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="[25, 100, 500, 1000]"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxRemoteOperations
                :filtering = "false"
                :paging = "true"
                :sorting = "false"
                :summary = "false"
                :grouping = "false"
                :group-paging = "false"
            />
        </DxDataGrid>
    </div>
</template>


<script>
     const pageSettings = {
        size: 25,
        number: 0
    };

    export default {
        name: "LicenseRequests",
        setup(){
            const page = pageSettings;
            const notify = inject('notify');
            const flexBasis = ref('');
            let selectedRequest = ref({});
            const licenseRequestsDataGrid = ref(null);

            const router = useRouter();

            const onRowPrepared = (e) => {
                if(!e.data) return;
                if(e.data.requestDataChanged)
                    e.rowElement.classList.add('row-warning');
            };
            
            const onSelectionChanged = ({ selectedRowsData }) => {
                if(!selectedRowsData.length)
                {
                    selectedRequest.value = {};
                    return;
                }
                
                const data = selectedRowsData[0];
                selectedRequest.value = { id: data.id };
            };

            const refreshDataGrid = () => {
                licenseRequestsDataGrid.value.instance.refresh();
            };

            const createLicense = async (requestId) => {
                try {
                    const response = await LicenseRequestService.createNewLicenseFromRequest(requestId);
                    console.log(response.newLicenseId);
                    router.push({ name: 'licenses', params: { id: response.newLicenseId } })
                } catch (error) {
                    if(error instanceof CreateLicenseError){
                        notify(`${error.errorCode} ${error.message}`, 'error', 1500);
                    }

                    console.log(error);
                }
            }

            const deleteLicenseRequest = async (requestId) => {
                try {
                  await LicenseRequestService.deleteLicenseRequest(requestId);
                  licenseRequestsDataGrid.value.instance.clearSelection();
                  refreshDataGrid();
                } catch (error) {
                    if(error instanceof DeleteLicenseRequestError){
                        notify(`${error.errorCode} ${error.message}`, 'error', 1500);
                    }

                    console.log(error);
                }
            }

            const onToolbarPreparing = (e) => {
                e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: "refresh",
                        onClick: refreshDataGrid.bind(this)
                    }
                },)
            };

            const addMenuItems = (e) => {               
                if (e.target == 'content') {
                    // e.items can be undefined
                    if (!e.items) e.items = [];
    
                    // Add a custom menu item
                    e.items.push({
                        text: 'Create license',
                        onItemClick: async () => {
                            await createLicense(e.row.data.id);
                        }
                    });

                    e.items.push({
                        text: 'Delete request',
                        onItemClick: async () => {
                            await deleteLicenseRequest(e.row.data.id);
                        }
                    });
                } 
            }

            return { 
                page, 
                flexBasis, 
                licenseRequestsDataGrid, 
                selectedRequest, 
                onSelectionChanged,
                onRowPrepared,
                refreshDataGrid,
                onToolbarPreparing,
                deleteLicenseRequest,
                createLicense,
                notify,
                addMenuItems
            };
        },
        data() {
            return {
                requestsStore: LicenseRequestStore,
                showLicenseRequestInfo: false,
            }
        },
        components: {
            DxDataGrid,
            DxColumn, 
            DxFilterRow,
            DxSearchPanel,
            DxGroupPanel,
            DxPager,
            DxPaging,
            DxRemoteOperations,
            DxColumnChooser,
            DxLoadPanel
        },
    }

    import { 
        ref,
        inject 
    } 
    from 'vue'
    
    import { useRouter } from 'vue-router'

    import { 
        DxDataGrid,    
        DxFilterRow,
        DxSearchPanel,
        DxGroupPanel,
        DxPager,
        DxPaging,
        DxRemoteOperations,
        DxColumnChooser,
        DxLoadPanel,
        DxColumn
    } from 'devextreme-vue/data-grid';

    import { LicenseRequestService, DeleteLicenseRequestError, CreateLicenseError, LicenseRequestStore } from '@/services/license-requests-service';  
</script>

<style lang="scss" scoped>
.license-requests-content{
    display: grid;
    grid-template-columns: 1fr 0%;
}
</style>

<style lang="scss">
.row-warning {
    background-color: rgba(220, 133, 132, 0.73);
}
</style>