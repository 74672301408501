import ApiService from "./api-service";
import CustomStore from 'devextreme/data/custom_store';


async function loadLicenseRequests(loadOptions) {
  let params = {
    pageNumber : Math.ceil(loadOptions["skip"] / loadOptions["take"] + 1),
    pageSize : loadOptions["take"]
  };
  
  const requestData ={
    method: "GET",
    url: "/licenserequests",
    params: params
  };

  try {
    const data = await ApiService.customRequest(requestData);

    return {
       data: data.data.pagedData,
       totalCount: data.data.totalCount,
       summary: data.data.summary,
       groupCount: data.data.groupCount
     };
  } catch (error) {
    throw new Error(error.message);
  }
  
  
  // try {
  //   const response=await fetch(request);
  //   const data=await response.json();
  //   return {
  //     data: data.pagedData,
  //     totalCount: data.totalCount,
  //     summary: data.summary,
  //     groupCount: data.groupCount
  //   };
  // } catch(error) {
  //   notify(error, 'error', 3000);
  // }
}

const LicenseRequestStore = new CustomStore({
  key: 'id',
  load: loadLicenseRequests
});


class DeleteLicenseRequestError extends Error {
  constructor(errorCode, message) {
      super(message)
      this.name = this.constructor.name
      this.message = message
      this.errorCode = errorCode
  }
}

class CreateLicenseError extends Error {
  constructor(errorCode, message) {
      super(message)
      this.name = this.constructor.name
      this.message = message
      this.errorCode = errorCode
  }
}

const LicenseRequestService = {
  deleteLicenseRequest: async function (requestId) {
    const requestData = {
      method: 'DELETE',
      url: "/licenserequests",
      params: {
          id: requestId
      }
    };

    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new DeleteLicenseRequestError(error.response.status, error.response.data.detail ?? error.response.data.title)
    }
  },
  
  createNewLicenseFromRequest: async function(requestId){
    const requestData = {
      method: 'POST',
      url: "/licenserequests/create_license",
      data: {
          id: requestId
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new CreateLicenseError(error.response.status, error.response.data.detail ?? error.response.data.message)
    }
  }
};

export default LicenseRequestService

export { LicenseRequestStore, LicenseRequestService, DeleteLicenseRequestError, CreateLicenseError }